import { Outlet } from 'react-router-dom';
import { useParams } from 'react-router';
import { useGetExternalDevicesQuery, useGetUsersQuery } from 'features/api/apiSlice';
import MainCard from 'components/MainCard';

// material-ui
import { Avatar, Box, Grid, List, ListItem, ListItemAvatar, ListItemText, Tab, Tabs, Typography } from '@mui/material';
import { LineChartOutlined } from '@ant-design/icons';
import { getAvatar } from 'helpers/UserExtensions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import EmptyUserCard from 'components/cards/skeleton/EmptyUserCard';
import { PatientChart } from 'components/charts/PatientChart';
import { selectCurrentUser } from 'features/auth/authSlice';
import { useAppSelector } from 'app/hooks';
  
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

// ==============================|| CUSTOMER - CARD PREVIEW ||============================== //
  
export default function PatientFileDialog () {
  const { t } = useTranslation();
  const authUser = useAppSelector(selectCurrentUser);
  const { userid } = useParams();
  
  const { data: users, isLoading: isLoadingUsers, isFetching: isFetchingUsers } = useGetUsersQuery({ parentId: authUser!!.id!!, role: null })

  const { data: externalDevices, isLoading: isLoadingExternalDevices, isFetching: isFetchingExternalDevices } = useGetExternalDevicesQuery({})

  if (isLoadingUsers || isLoadingExternalDevices) {
    return (
      <Grid container spacing={3}>
          <EmptyUserCard title={t('loading', 'Loading...')} />
      </Grid>
    )
  } else if (isFetchingUsers || isFetchingExternalDevices) {
    return (
      <Grid container spacing={3}>
          <EmptyUserCard title={t('fetching', 'Fetching...')} />
      </Grid>
    )
  } else {
    const patient = users!!.find(x => x.id == userid)

    return (
      <MainCard title={
          <List sx={{ width: 1, p: 0 }}>
            <ListItem disablePadding>
              <ListItemAvatar
                style={{ marginRight: "15px" }}>
                <Avatar src={getAvatar(patient!!.id!!)} sx={{ width: 70, height: 70, boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }} />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Box marginBottom={1}>
                    <Typography fontSize={14} fontWeight="bold" lineHeight="normal" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">{patient!!.lastName}</Typography>
                    <Typography color="grey" fontSize={14} lineHeight="normal" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">{patient!!.firstName}</Typography>
                  </Box>
                }
                secondary={
                  <Box>
                    <Typography color="grey" fontSize={12} lineHeight="normal" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">{t('age', 'Age')}</Typography>
                    <Typography color="grey" fontSize={12} lineHeight="normal" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">{t('gender', 'Gender')}</Typography>
                  </Box>
                }
              />
            </ListItem>
          </List>
        }>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}>
          <Tabs value={0} variant="scrollable" scrollButtons="auto" aria-label="account profile tab">
            <Tab label={t('charts', 'Charts')} icon={<LineChartOutlined />} iconPosition="start" sx={{textTransform: 'none'}} {...a11yProps(3)}/>
          </Tabs>
        </Box>
        <CustomTabPanel value={0} index={0}>
          <PatientChart patientId={patient!!.id!!} users={users!!.concat(authUser!!)} externalDevices={externalDevices!!}/>
        </CustomTabPanel>
        <Box sx={{ mt: 2.5 }}>
          <Outlet />
        </Box>
      </MainCard>
    )
  }
}
  